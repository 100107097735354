import React from "react";

export const VuesaxBulkSms1 = ({ className }) => {
  return (
    <svg
      className={`vuesax-bulk-sms-1 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M28.3333 34.1666H11.6666C6.66659 34.1666 3.33325 31.6666 3.33325 25.8333V14.1666C3.33325 8.33325 6.66659 5.83325 11.6666 5.83325H28.3333C33.3333 5.83325 36.6666 8.33325 36.6666 14.1666V25.8333C36.6666 31.6666 33.3333 34.1666 28.3333 34.1666Z"
        fill="white"
        opacity="0.4"
      />
      <path
        className="path"
        d="M20 21.4499C18.6 21.4499 17.1833 21.0166 16.1 20.1333L10.8833 15.9666C10.35 15.5333 10.25 14.7499 10.6833 14.2166C11.1166 13.6833 11.9 13.5833 12.4333 14.0166L17.6499 18.1833C18.9166 19.2 21.0666 19.2 22.3333 18.1833L27.55 14.0166C28.0833 13.5833 28.8833 13.6666 29.3 14.2166C29.7333 14.7499 29.65 15.55 29.1 15.9666L23.8833 20.1333C22.8166 21.0166 21.4 21.4499 20 21.4499Z"
        fill="white"
      />
    </svg>
  );
};
