import React from "react";

export const VuesaxBulkLocationMinus1 = ({ className }) => {
  return (
    <svg
      className={`vuesax-bulk-location-minus-1 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M34.3667 14.5002C32.6333 6.7835 25.9 3.3335 20 3.3335C20 3.3335 20 3.3335 19.9833 3.3335C14.1 3.3335 7.38333 6.7835 5.63333 14.4835C3.66666 23.0835 8.93333 30.3668 13.7 34.9668C15.4667 36.6668 17.7333 37.5168 20 37.5168C22.2667 37.5168 24.5333 36.6668 26.2833 34.9668C31.05 30.3668 36.3167 23.1002 34.3667 14.5002Z"
        fill="white"
        opacity="0.4"
      />
      <path
        className="path"
        d="M24.5833 19.5835H15.4167C14.7333 19.5835 14.1667 19.0168 14.1667 18.3335C14.1667 17.6502 14.7333 17.0835 15.4167 17.0835H24.5833C25.2667 17.0835 25.8333 17.6502 25.8333 18.3335C25.8333 19.0168 25.2667 19.5835 24.5833 19.5835Z"
        fill="white"
      />
    </svg>
  );
};
