import React from "react";

export const VuesaxBulkCall1 = ({ className }) => {
  return (
    <svg
      className={`vuesax-bulk-call-1 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M19.65 23.6833L14.2 29.1333C13.6 28.5999 13.0167 28.0499 12.45 27.4833C10.7333 25.7499 9.18333 23.9333 7.79999 22.0333C6.43333 20.1333 5.33333 18.2333 4.53333 16.3499C3.73333 14.4499 3.33333 12.6333 3.33333 10.8999C3.33333 9.76658 3.53333 8.68325 3.93333 7.68325C4.33333 6.66659 4.96666 5.73325 5.84999 4.89992C6.91666 3.84992 8.08333 3.33325 9.31666 3.33325C9.78333 3.33325 10.25 3.43325 10.6667 3.63325C11.1 3.83325 11.4833 4.13325 11.7833 4.56659L15.65 10.0166C15.95 10.4333 16.1667 10.8166 16.3167 11.1833C16.4667 11.5333 16.55 11.8833 16.55 12.1999C16.55 12.5999 16.4333 12.9999 16.2 13.3833C15.9833 13.7666 15.6667 14.1666 15.2667 14.5666L14 15.8833C13.8167 16.0666 13.7333 16.2833 13.7333 16.5499C13.7333 16.6833 13.75 16.7999 13.7833 16.9333C13.8333 17.0666 13.8833 17.1666 13.9167 17.2666C14.2167 17.8166 14.7333 18.5333 15.4667 19.3999C16.2167 20.2666 17.0167 21.1499 17.8833 22.0333C18.4833 22.6166 19.0667 23.1833 19.65 23.6833Z"
        fill="white"
        opacity="0.4"
      />
      <path
        className="path"
        d="M36.6167 30.5499C36.6167 31.0166 36.5333 31.4999 36.3667 31.9666C36.3167 32.0999 36.2667 32.2332 36.2 32.3666C35.9167 32.9666 35.55 33.5332 35.0667 34.0666C34.25 34.9666 33.35 35.6166 32.3333 36.0332C32.3167 36.0332 32.3 36.0499 32.2833 36.0499C31.3 36.4499 30.2333 36.6666 29.0833 36.6666C27.3833 36.6666 25.5667 36.2666 23.65 35.4499C21.7333 34.6332 19.8167 33.5332 17.9167 32.1499C17.2667 31.6666 16.6167 31.1832 16 30.6666L21.45 25.2166C21.9167 25.5666 22.3333 25.8332 22.6833 26.0166C22.7667 26.0499 22.8667 26.0999 22.9833 26.1499C23.1167 26.1999 23.25 26.2166 23.4 26.2166C23.6833 26.2166 23.9 26.1166 24.0833 25.9332L25.35 24.6832C25.7667 24.2666 26.1667 23.9499 26.55 23.7499C26.9333 23.5166 27.3167 23.3999 27.7333 23.3999C28.05 23.3999 28.3833 23.4666 28.75 23.6166C29.1167 23.7666 29.5 23.9832 29.9167 24.2666L35.4333 28.1832C35.8667 28.4832 36.1667 28.8332 36.35 29.2499C36.5167 29.6666 36.6167 30.0832 36.6167 30.5499Z"
        fill="white"
      />
    </svg>
  );
};
